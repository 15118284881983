import React, { useState, useContext, useEffect }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import Graph from './Graph'
import '@bryntum/grid/grid.stockholm.css';
import ReactPaginate from 'react-paginate';
import { RelativeIndicatorContext } from 'context/RelativeIndicator';


const Content = styled.div`
  > div {
    height: calc(100vh - 120px);
  }

  .b-grid-header-text {
    font-size: 14px;
    font-weight: 500;
  }

  // .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell:not(.b-focused) {
  //   background-color: #2faf80 !important;
  // }

  // .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell:not(.b-focused):first-child {
  //   background-color: rgba(15,53,95, 0.02) !important;
  }

  .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell:not(.b-focused) {
    background-color: rgba(15,53,95, 0.02) !important;
  }

  .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .bg-success.b-grid-cell {
    background-color: #2faf80 !important;
  }

  .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .bg-warning.b-grid-cell {
    background-color: #efaa34  !important;
  }

  .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .bg-danger.b-grid-cell {
    background-color: #f25252 !important;
  }

  .pagination {
    color: #000;
    margin: 0;

    ${props => props.loading ? `
    opacity: 0;
    ` : ''}

    ul {
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0;

      li {
        margin: 10px;
        // color: #3183fe;

        a {
          color: #3183fe;
        }

        &.selected {
          a {
            color: #666;
            cursor: default;
          }
        }
      }
    }
  }
`

const itemsPerPage = 100;

function CultureIndicatorsPage() {
  const {
    painelSuplicas, getPainelSuplicas,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  const [usedSearchPayload, setUsedSearchPayload] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (painelSuplicas && painelSuplicas.header) {
      setPageCount(painelSuplicas.header.total / itemsPerPage);
    }
  }, [painelSuplicas]);
  

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);

    setItemOffset(newOffset);
    getPainelSuplicas({
      ...usedSearchPayload,
      offset: newOffset,
    });
  };

  return (

    <InternalLayout
      title="Painel das súplicas"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getPainelSuplicas({
            ...payload,
          });
          setUsedSearch(!usedSearch);
          setUsedSearchPayload(payload);
        }} />
      }
    >
      <Loading show={painelSuplicas.loading} size="big" />
      <Content loading={painelSuplicas.loading}>

        {painelSuplicas.loading ? (
          <p></p>
        ) : painelSuplicas && (!painelSuplicas.data || !painelSuplicas.data.length) ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <>
            <Graph
              graphData={painelSuplicas.data}
              graphHeader={painelSuplicas.header}
              usedSearchPayload={usedSearchPayload}
            />
          </>
        )}
        <p className='pagination' >
          <ReactPaginate
            breakLabel="..."
            nextLabel="avançar >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< voltar"
            renderOnZeroPageCount={false}
          />
        </p>
      </Content>
    </InternalLayout>
  );
}

export default CultureIndicatorsPage;
