import React, {useState, useEffect}  from 'react';
import { getUserData, getRefreshToken } from 'services/getUserData';
import { Dashboard } from './styles';

import logo from 'assets/capital-relacional.png';
import HappyBiz from 'assets/logo/logo-white.svg';
import Cloud from 'assets/capital/cloud.svg';
import UsersClass from 'assets/capital/users-class.svg';
import Pray from 'assets/capital/pray.svg';
import StreetView from 'assets/capital/street-view.svg';
import Fireplace from 'assets/capital/fireplace.svg';
import PollPeople from 'assets/capital/poll-people.svg';
import Diagnoses from 'assets/capital/diagnoses.svg';
import UserVisor from 'assets/capital/user-visor.svg';
import UserChart from 'assets/capital/user-chart.svg';
import SendInvites from 'assets/capital/send-invites.svg';
import SendLayers from 'assets/capital/send-layers.svg';
import Playlist from 'assets/capital/playlist.svg';
import Article from 'assets/capital/article.svg';
import SaveUser from 'assets/capital/save-user.svg';
import Close from 'assets/capital/close.svg';

function DashboardPage() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  return (
    <Dashboard>
      <div className="container">
        <header>
          <a title="Capital Relacional" className="logo" href="/#/dashboard">
            <img src={logo} alt="Capital Relacional" />
          </a>
          <div className="welcome">
            <h1>
              👋 Seja bem-vindo, {user?.name}!
            </h1>
            <a title={`plano ${user?.plan}`} className={`plan ${user?.planClass}`} href="/#/dashboard">
              plano <strong>{user?.plan}</strong>
            </a>
          </div>
          <a title="Sair da Capital Relacional" href="/#/logout" className="button-close">
            <img src={Close} alt="Sair da Capital Relacional" />
          </a>
        </header>
        <main>
          <aside>
            <h3>Recursos estruturais</h3>
            <div className="menu">
              <a title="Gestão de respondentes" href="/#/dashboard">
                <img src={SaveUser} alt="Gestão de respondentes" />
                Gestão de respondentes
              </a>
              <a title="Envios de convites" href="/#/dashboard">
                <img src={SendInvites} alt="Envios de convites" />
                Envios de convites
              </a>
              <a title="Envio por camadas" href="/#/dashboard">
                <img src={SendLayers} alt="Envio por camadas" />
                Envio por camadas
              </a>
              <a title="Playslist de vídeos" target="_blank" href="https://youtube.com/playlist?list=PLFF7eD2FaPLcE6KQDqgmI6Ttkce7cfkLk" rel="noreferrer">
                <img target="_blank" src={Playlist} alt="Playslist de vídeos" />
                Playslist de vídeos
              </a>
              <a title="Artigos" target="_blank" href="https://www.robertotranjan.com.br/categoria/capitalrelacional/" rel="noreferrer">
                <img target="_blank" src={Article} alt="Artigos" />
                Artigos
              </a>
            </div>
          </aside>
          <nav>
            <h3>Ferramentas relacionais</h3>
            <div className="menu">
              <a className={user?.hasOportunityCloudPage ? '' : 'disabled'} href="/#/nuvem-de-oportunidades" title="Nuvem de oportunidades">
                <img src={Cloud} alt="Nuvem de oportunidades" />
                Nuvem de oportunidades
              </a>
              <a className={user?.hasRelativeIndicators ? '' : 'disabled'} href="/#/indicadores-relacionais" title="Indicadores relacionais">
                <img src={UsersClass} alt="Indicadores relacionais" />
                Indicadores relacionais
              </a>
              <a className={user?.hasProminentSupplication ? '' : 'disabled'} href="/#/suplicas-em-destaque" title="Súplicas em destaque">
                <img src={Pray} alt="Súplicas em destaque" />
                Súplicas em destaque
              </a>
              <a className={user?.hasBussinessLayers ? '' : 'disabled'} href="/#/camadas-de-negocio" title="Camadas de negócio">
                <img src={PollPeople} alt="Camadas de negócio" />
                Camadas de negócio
              </a>
              <a className={user?.hasRelationalCompetence ? '' : 'disabled'} href="/#/competencia-relacional" title="Competência relacional">
                <img src={Fireplace} alt="Competência relacional" />
                Competência relacional
              </a>
              <a className={user?.hasCultureIndicators ? '' : 'disabled'} href="/#/indicador-de-cultura" title="Cultura relacional">
                <img src={StreetView} alt="Cultura relacional" />
                Cultura relacional
              </a>
              <a className={user?.hasRelationalPositionPage ? '' : 'disabled'} href="/#/posicionamento-relacional" title="Posicionamento relacional">
                <img src={Diagnoses} alt="Posicionamento relacional" />
                Posicionamento relacional
              </a>
              <a className={user?.hasSupplicationDashboard ? '' : 'disabled'} href="/#/painel-das-suplicas" title="Painel das súplicas">
                <img src={UserVisor} alt="Painel das súplicas" />
                Painel das súplicas
              </a>
              <a className={user?.hasRelationalHistoricPage ? '' : 'disabled'} href="/#/historico-relacional" title="Histórico relacional">
                <img src={UserChart} alt="Histórico relacional" />
                Histórico relacional
              </a>
            </div>
          </nav>
        </main>
      </div>
      <footer>
          <a target="_blank" className="happybiz" href="https://simplesnologia.biz/" rel="noreferrer">
            <img src={HappyBiz} alt="HappyBiz - Simplesnologia" />
          </a>
          <h6>Mercados são negócios. Negócios são conversas. Conversas são pessoas. Pessoas são relacionamentos.</h6>
          <div className="icons">
            <a target="_blank" className="instagram" href="https://www.instagram.com/capitalrelacional/" rel="noreferrer">
              <i className="fa fa-instagram"></i>
            </a>
            <a target="_blank" className="linkedin" href="https://www.linkedin.com/company/capital-relacional/" rel="noreferrer">
              <i className="linkedin fab fa-linkedin"></i>
            </a>
          </div>
        </footer>
    </Dashboard>
  );
}

export default DashboardPage;
