import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Graph from './Graph'
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`

const CenterAgents = styled.h4`
  text-align: center;
  padding-top: 10px;
`

let camadas = {
  1: 'Cliente',
  2: 'Colaborador',
  5: 'Líder'
}

function ProminientSupplicationPage() {
  const {
    prominientSupplication, getProminientSupplication,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  const [camadasSelectionadas, setCamadasSelectionadas] = useState();

  return (
    <InternalLayout
      title="Súplicas em destaque"
      toogledSearch={usedSearch}
      filters={
        <Filters
          onSearch={(payload) => {
            getProminientSupplication(payload);
            setUsedSearch(!usedSearch);

            let agentesCamada = ['Todas as Camadas'];

            if (payload.idsAgentesHolograma && payload.idsAgentesHolograma.length) {
              agentesCamada = [];
              const selected = payload.idsAgentesHolograma.split('|;');
              selected.forEach(element => {
                if (camadas[element]) {
                  agentesCamada.push(camadas[element])
                }
              });
            }
            setCamadasSelectionadas(agentesCamada);
          }}
          onAgents={(data) => {
            camadas = {};
            data.forEach(item => {
              camadas[item.value] = item.label;
            })
          }}
        />
      }
    >
      <Loading show={prominientSupplication.loading} size="big" />
      <Content>
        {prominientSupplication.loading ? (
          <p>.</p>
          ) : prominientSupplication && prominientSupplication.error ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
          ) : (
          <>
            {camadasSelectionadas && (
              <CenterAgents>
                {camadasSelectionadas.join(' - ')}
                {prominientSupplication.length && prominientSupplication[0].relacao_icr?.length ? ` (${prominientSupplication[0].relacao_icr})` : ''}
              </CenterAgents>
            )}
            <Graph
              graphData={prominientSupplication}
            />
          </>
        )}
      </Content>
    </InternalLayout>
  );
}

export default ProminientSupplicationPage;
