import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import { getUserData } from 'services/getUserData';
import Select from "react-select";

import { RelativeIndicatorContext } from 'context/RelativeIndicator';
import { object } from '@amcharts/amcharts5';
import { set } from 'draft-js/lib/EditorState';

const FilterWrapper = styled.div`
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const FilterTitle = styled.p`
  color: #FFF;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Button = styled.button`
  background: rgba(2,255,103,.4);
  border-radius: 5px;
  color: #0F355F;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;
  width: 100%;

  :hover {
    opacity: .8;
  }
`;

const types = [{
  value: 'R',
  label: 'Por respondentes',
}, {
  value: 'C',
  label: 'Por camadas de negócio',
},]

function Filters({ onSearch, onAgents = () => {}, onlyAgents, changeType }) {
  const {
    partners, getPartners,
    clients, getClients,
    periods, getPeriods,
    agents, getAgents,
    camadas, getCamadas,
    camadasNegocio, getCamadasNegocio,
    clearFilters,
  } = useContext(RelativeIndicatorContext);

  const [partner, setPartner] = useState();
  const [client, setClient] = useState();
  const [period, setPeriod] = useState();
  const [agent, setAgent] = useState();
  const [colab, setColab] = useState();
  const [leaders, setLeaders] = useState();
  const [clien, setClien] = useState();
  const [franchise, setFranchise] = useState();
  const [userData, setUserData] = useState({});

  const [colabsF, setColabF] = useState();
  const [leadersF, setLeadersF] = useState();
  const [clientsF, setClientsF] = useState();
  const [franchiseF, setFranchiseF] = useState();

  const [type, setType] = useState(types[0]);
  const [camada, setCamada] = useState();

  const [filteredAgent, setFilteredAgent] = useState([]);

  const [currentRequested, setCurrentRequested] = useState();
  const [firstLoaded, setFirstLoaded] = useState();

  useEffect(() => {
    setUserData(getUserData());
    // getPartners();

    return () => {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    if (!partners.loading) {
      setPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (userData.name) {
      if (!userData.idPartner) {
        getPartners();
      } else {
        setPartner({ value: userData.idPartner })
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!clients.loading && periods.loading) {
      setClient(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (!periods.loading) {
      setPeriod(periods[0]);
      if (!firstLoaded && partner && client) {
        onSearch({
          idParceiro: partner.value,
          idCliente: client.value,
          idPeriodoResposta: periods[0].value,
          offset: 0,
          fetch: 100
        })
        setFirstLoaded(true);
      }
    }
  }, [periods]);

  useEffect(() => {
    if (!camadasNegocio.loading) {
      if (currentRequested === 'colabsF') {
        setColabF(camadasNegocio);
      } else if (currentRequested === 'leadersF') {
        setLeadersF(camadasNegocio);
      } else if (currentRequested === 'clientsF') {
        setClientsF(camadasNegocio);
      } else if (currentRequested === 'franchiseF') {
        setFranchiseF(camadasNegocio);
      }
    }
  }, [camadasNegocio]);

  useEffect(() => {
    if (!userData.idClient) {
      setClient();
      setPeriod();

      if (partner) {
        getClients({
          idParceiro: partner.value,
        })
      }
    } else {
      setClient({ value: userData.idClient });
    }
  }, [partner]);

  useEffect(() => {
    if (agent) {
      if (agent.value) {
        getCamadas({
          idParceiro: partner.value,
          idCliente: client.value,
          idAgenteHolograma: agent.value,
        })
      } else {
        setFilteredAgent(agent.map(item => item.value));
      }
    } else {
      setFilteredAgent([]);
    }
  }, [agent]);

  useEffect(() => {
    setPeriod();

    if (client) {
      setColabF();
      setLeadersF();
      setClientsF();
      setFranchiseF();
      setAgent();
      setCamada();
      getPeriods({
        idParceiro: partner.value,
        idCliente: client.value,
      })
      getAgents({
        idParceiro: partner.value,
        idCliente: client.value,
      })
    }
  }, [client]);

  useEffect(() => {
    if (agents && agents.length) {
      setColabF();
      setLeadersF();
      setClientsF();
      setAgent();
      setCamada();
      onAgents(agents);

      agents.forEach((item, i) => {
        setTimeout(() => {
          switch (item.value) {
            case '1':
              setCurrentRequested('clientsF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value,
              })
              break;
            case '5':
              setCurrentRequested('leadersF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value,
              })
              break;
            case '9':
              setCurrentRequested('franchiseF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value,
              })
              break;
            case '2':
              setCurrentRequested('colabsF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value,
              })
              break;
            default:
          }
        }, 1000 * i);
      })
    }
  }, [agents]);

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>
      {userData.idPartner ? null : (
        <Select
          placeholder="Parceiro"
          value={partner}
          options={partners || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setPartner(Object);
          }}
        />
      )}
      {userData.idClient ? null : (
        <Select
          placeholder="Cliente"
          value={client}
          options={clients || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setClient(Object);
          }}
        />
      )}
      <Select
        placeholder="Período"
        value={period}
        options={periods || []}
        classNamePrefix="select2-selection"
        onChange={(Object) => {
          setPeriod(Object);
        }}
      />
      {changeType ? (
        <Select
          placeholder="Tipo"
          value={type}
          options={types || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setType(Object);
            setFilteredAgent([]);
          }}
        />
      ) : null}

      {changeType && type.value === 'C' ? (
        <>
          <Select
            placeholder="Agente do Holograma"
            value={agent}
            options={agents || []}
            classNamePrefix="select2-selection"
            // defaultValue={parceiro || []}
            onChange={(Object) => {
              setAgent(Object);
              setCamada();
            }}
          />
          {camadas.length ? (
            <Select
              placeholder="Camada de negócio"
              value={camada}
              options={camadas || []}
              classNamePrefix="select2-selection"
              // defaultValue={parceiro || []}
              onChange={(Object) => {
                setCamada(Object);
              }}
            />
          ) : null}
        </>
      ) : null}

      {!changeType || (changeType && type.value === 'R') ? (
        <>
          <Select
            placeholder="Todas as Relações"
            // value={parceiro}
            options={agents || []}
            isMulti
            classNamePrefix="select2-selection"
            // defaultValue={parceiro || []}
            onChange={(Object) => {
              setAgent(Object);
            }}
          />

          {onlyAgents ? null : (
            <>
              {(clientsF || colabsF || leadersF || franchiseF) && (
                <hr style={{ borderColor: '#fff', marginBottom: 24 }} />
              )}
              {(clientsF && clientsF.length && (!filteredAgent.length || filteredAgent.includes('1'))) && (
                <Select
                  placeholder="Camada de negócio Cliente"
                  // value={parceiro}
                  options={clientsF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setClien(a);
                  }}
                />
              )}
              {(colabsF && colabsF.length && (!filteredAgent.length || filteredAgent.includes('2'))) && (
                <Select
                  placeholder="Camada de negócio Colaborador"
                  // value={parceiro}
                  options={colabsF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setColab(a);
                  }}
                />
              )}
              {(leadersF && leadersF.length && (!filteredAgent.length || filteredAgent.includes('5'))) && (
                <Select
                  placeholder="Camada de negócio Líder"
                  // value={parceiro}
                  options={leadersF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setLeaders(a);
                  }}
                />
              )}
              {(franchiseF && franchiseF.length && (!filteredAgent.length || filteredAgent.includes('9'))) && (
                <Select
                  placeholder="Camada de negócio Franquias"
                  // value={parceiro}
                  options={franchiseF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setFranchise(a);
                  }}
                />
              )}
            </>
          )}
        </>
      ) : null}

      <ButtonWrapper>
        <Button
          onClick={(e) => {
            let idsAgentesHolograma = null;
            let idsRelacoesCamadasOpcoes = '';

            if (agent && agent.length) {
              idsAgentesHolograma = agent.map(item => item.value).join('|;') + '|;'
            }

            if (colab && colab.length) {
              const colabOpt = colab.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + colabOpt;
            }

            if (leaders && leaders.length) {
              const leadersOpt = leaders.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + leadersOpt;
            }

            if (clien && clien.length) {
              const clienOpt = clien.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + clienOpt;
            }

            if (franchise && franchise.length) {
              const franchiseOpt = franchise.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + franchiseOpt;
            }

            let data = {
              idParceiro: partner.value,
              idCliente: client.value,
              idPeriodoResposta: period.value,
              idsAgentesHolograma,
              offset: 0,
              fetch: 100
            }

            if (idsRelacoesCamadasOpcoes.length) {
              data.idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
            };

            if (changeType) {
              if (type.value === 'C') {
                data = {
                  idParceiro: partner.value,
                  idCliente: client.value,
                  idPeriodoResposta: period.value,
                  idTipoPosicionamento: type.value,
                  idsRelacoesCamadasOpcoes: (camada?.value) ? camada.value + '|;' : null,
                  idsAgentesHolograma: (agent?.value) ? agent.value + '|;' : null,
                  offset: 0,
                  fetch: 100
                }
                // this to update graph agents
                onAgents(camadas);
              } else {
                data.idTipoPosicionamento = type.value;
                onAgents(agents);
              }
            }

            onSearch(data)
          }}
          disabled={false}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;
