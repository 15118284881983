import React from "react";
import styled from 'styled-components';
import Logo from 'assets/capital-relacional.png';
import Instagram from 'assets/capital/instagram.svg';
import Linkedin from 'assets/capital/linkedin.svg';
import Youtube from 'assets/capital/youtube.svg';

export const Footer = styled.div`
  background: #0F355F;

  .logo {
    max-width: 150px;
  }

  > div {
    max-width: 1240px;
    margin: 0 auto;
    padding: 16px 0;
    background: #0F355F;
    display: flex;
    align-items: center;
  }
`

export const FooterSocial = styled.div`
  margin: 0 70px;

  a {
    margin-right: 10px;
  }
`

export const LinkList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  grid-template-rows: 20px 20px 20px;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  grid-gap: 5px;

  a {
    font-size: 14px;
    color: #FFF;
  }
`

function FooterComponent() {
  return (
    <Footer>
      <div>
        <a href="/">
          <img class="logo" src={Logo} alt="Capital Relacional" />
        </a>
        <FooterSocial>
          <a href="https://www.instagram.com/capitalrelacional" target="_blank">
          <img src={Instagram} alt="Capital Relacional" />
          </a>
          <a href="https://www.instagram.com/capitalrelacional" target="_blank">
          <img src={Linkedin} alt="Capital Relacional" />
          </a>
          <a href="https://youtube.com/playlist?list=PLFF7eD2FaPLcE6KQDqgmI6Ttkce7cfkLk" target="_blank">
          <img src={Youtube} alt="Capital Relacional" />
          </a>
        </FooterSocial>
        <LinkList>
          <a href="">Plataforma</a>
          <a href="">Onboard</a>
          <a href="/#/planos">Planos</a>
          <a href="/#/sobre-nos">Sobre nós ...</a>
        </LinkList>
      </div>
    </Footer>
  );
};

export default FooterComponent;
