import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import Graph from './Graph'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  margin-top: 10px;
  > div {
    height: calc(100vh - 75px);
  }

  .border2 {
    border: 2px solid #eff2f7;
  }

  .col-sm-2 .border2 .card-body {
    padding-bottom: 16px;
  }

  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
`

function OportunityCloudPage() {
  const {
    icr, getICR,
    cloudData, getCloudData,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  console.log(icr)
  return (
    <InternalLayout
      title="Nuvem de oportunidades"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICR({
            ...payload,
            visualizacao: 'relacao',
            analiseRelacoes: 1
          });
          getCloudData({
            ...payload,
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={icr.loading} size="big" />
      <Content>
        {(icr.loading) ? (
          <p></p>
        ) : icr && (!icr.data || !icr.data.length) ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Graph
            graphData={icr}
            cloudData={cloudData.loading ? null : cloudData}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default OportunityCloudPage;
