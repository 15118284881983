import React, { useEffect } from 'react';
import { Button, Container, Col, Row, Card, CardBody } from 'reactstrap';
import Grafico from './Grafico';
import { retornarCorSuplicas } from "../../utils"
import { retornarGraficoCapitalRelacionalDinamico } from "../../utils/utils-amcharts";

const suplicas = [
  "suplica_1_",
  "suplica_2_",
  "suplica_3_",
  "suplica_4_",
  "suplica_5_",
  "suplica_6_",
  "suplica_7_",
  "suplica_8_"
];

const engajamentos = [
  { titulo: "Convidados:", propriedade: "pesquisas_enviadas" },
  { titulo: "Respondentes:", propriedade: "pesquisas_feitas" },
  { titulo: "Em andamento:", propriedade: "pesquisas_andamento" },
  { titulo: "Pendentes:", propriedade: "pesquisas_nao_respondidas" }
]

const Graph = ({ graphData, cloudData, ...props }) => {
  const dados = graphData.data;

  useEffect(() => {
    if (!dados) return;

    dados.map((item, i) => {
      suplicas.map((suplica, i_suplica) => {
        setTimeout(() => {
          retornarGraficoCapitalRelacionalDinamico(item[`${suplica}resultado`], `painelGraficoSuplica_${i}_${i_suplica}`, { id: `graficoSuplica_${i}_${i_suplica}` });
        }, 1000);
      })
    })

  }, [dados]);

  return (
    <div>
      {dados?.map((item, i) => {
        if (item.relacao_icr)
          return (
            <Container style={{maxWidth: '1220px'}} fluid key={`painel_relacao_${i}`}>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={2}>
                      <Card style={{ marginBottom: "12px" }} color={item.relacao_cor}>
                        <CardBody className='text-white'>
                          <h6 className='text-white'>
                            <strong>
                              {item?.icr_descricao}
                            </strong>
                          </h6>
                          <div className={"d-flex"} style={{ height: 119 }}>
                            <div className={`align-self-center font-poppins flex-grow-1 text-center`} style={{ fontWeight: "bold", marginLeft: item.relacao_icr.includes('.') || item.relacao_icr.includes('+') ? "-5px" : "0", fontSize: item.relacao_icr.includes('.') || item.relacao_icr.includes('+') ? "54px" : "79px", paddingTop: "15px", paddingBottom: "15px", lineHeight: "45px" }}>
                              {item.relacao_icr}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={2} style={{ height: "100%" }}>
                      <Card outline className={`mb-0 border2 border-${item?.pesquisas_feitas_indicador == "verde" ? "success" : item?.pesquisas_feitas_indicador == "amarelo" ? "warning" : "danger"}`} color={item?.pesquisas_feitas_indicador == "verde" ? "success" : item?.pesquisas_feitas_indicador == "amarelo" ? "warning" : "danger"} >
                        <CardBody>
                          <div style={{ fontSize: "12px", paddingLeft: "26px" }}>
                            Engajamento
                          </div>
                          <div style={{ position: "absolute", top: "14px", left: "18px", fontSize: "20px" }}>
                            {item?.pesquisas_feitas_indicador === "vermelho" && <i className="fa fa-circle text-danger"></i>}
                            {item?.pesquisas_feitas_indicador === "verde" && <i className="fa fa-circle text-success"></i>}
                            {item?.pesquisas_feitas_indicador === "amarelo" && <i className="fa fa-circle text-warning"></i>}

                          </div>
                          <div className={"d-flex w-100"}>
                            <ul className={"w-100"} style={{ color: "#9092a1", fontSize: "11px", paddingLeft: "20px", marginBottom: "14px", marginTop: "14px" }}>
                              {
                                engajamentos?.map((engajamento, engajamento_i) => {
                                  return (
                                    <li className={"mt-2"} key={`engajamento${engajamento_i}`}>
                                      <div className={"d-flex"}>
                                        <div className={"flex-grow-1"}>{engajamento.titulo}</div>
                                        <div>
                                          <strong className='font-poppins'>
                                            {(item[engajamento.propriedade]).toLocaleString('pt-BR')}
                                          </strong>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              }
                            </ul>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={8} style={{ height: "100%" }}>
                      <Row>
                        {suplicas.map((suplica, i_suplica) => {
                          return (
                            <Col sm={3} className='' key={`idSuplica_${i_suplica}`}>
                              <Card outline className={`border2 border-${retornarCorSuplicas(item[`${suplica}resultado`])}`} style={{ height: 80 }}>
                                <CardBody className={'text-center p-2'} style={{ heigth: 70 }}>
                                  <div style={{ fontSize: 9 }}>
                                    {item[`${suplica}nome`]}
                                  </div>
                                  <div id={`painelGraficoSuplica_${i}_${i_suplica}`} className={'w-100'} style={{ position: "absolute", top: 20, left: 0 }}>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })
                        }
                      </Row>
                    </Col>

                  </Row>
                </Col>
                <Col sm={12} className='pr-0'>
                  <Card >
                    <CardBody style={{ height: "76px", overflowY: "hidden", animationDuration: "1s", animationIterationCount: 1, animationFillMode: "forwards" }} id={`collapse_${i}`}>
                      <div className="w-100" style={{ height: "calc(100vh - 373px)" }}>
                        {/* <h6>Nuvem de oportunidades</h6> */}
                        <Button outline disabled={!cloudData} color="secondary" id={`btn_collapse_${i}`} onClick={() => {

                          if (!document.getElementById(`collapse_${i}`).style.animationName || document.getElementById(`collapse_${i}`).style.animationName == "nuvemPalavrasFechada") {
                            document.getElementById(`collapse_${i}`).style.animationName = "nuvemPalavrasAberta";
                            document.getElementById(`btn_collapse_${i}`).classList.add("btn-outline-secondary-hover");
                            document.getElementById(`chartDiv_${i}`).style.opacity = 1;
                          }
                          else {
                            document.getElementById(`collapse_${i}`).style.animationName = "nuvemPalavrasFechada";
                            document.getElementById(`btn_collapse_${i}`).classList.remove("btn-outline-secondary-hover");
                            setTimeout(() => {
                              document.getElementById(`chartDiv_${i}`).style.opacity = 0;
                            }, 1000);
                          }
                        }}>
                          {cloudData ? 'Oportunidades' : 'Carregando'}
                            {/* <span className="badge bg-info ms-1 text-white">{cloudData?.filter(a => a.icr_id_agente_holograma == item.icr_id_agente_holograma).length || ""}</span> */}
                        </Button>
                        {cloudData ? (
                          <Grafico dados={cloudData} idAgenteHolograma={item.icr_id_agente_holograma} id={`chartDiv_${i}`} />
                        ) : null}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} className=''>
                  <hr className='mt-0 mb-2' />
                </Col>
              </Row>

            </Container>
          )
      })}
    </div>
  );
};

export default React.memo(Graph);
