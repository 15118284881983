import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";

am5.addLicense("AM5C303720496");

// const formatacaoAmCharts = retornarFormatacaoAmCharts();
//am4core.options.commercialLicense = true;

const Grafico = ({ dados, id, idAgenteHolograma }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados || [];
    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            document.querySelector(`#${id}`).innerHTML = "";
            var root = am5.Root.new(id);

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Add series
            // https://www.amcharts.com/docs/v5/charts/word-cloud/
            var series = root.container.children.push(am5wc.WordCloud.new(root, {
              categoryField: "icr_frases_palavras",
              valueField: "icr_quantidade",
              calculateAggregates: true,
              maxFontSize: am5.percent(70),
              minFontSize: am5.percent(10),
            }));

            // Had to set a heatRules for tooltips to work right
            series.set("heatRules", [{
              target: series.labels.template,
              dataField: "value",
              min: am5.color(0x999999),
              max: am5.color(0x000000),
              key: "fill"
            }]);

            series.labels.template.setAll({
              fontFamily: "Leelawadee",
              tooltipText: "[fontFamily: Leelawadee color=#fff fontSize:14px]{icr_nome_suplica}[/]"
            });
            
            if (idAgenteHolograma === 2) {
              const dataFiltered = dadosGrafico.filter(a => a.icr_id_agente_holograma == idAgenteHolograma);
              series.data.setAll(dataFiltered.splice(0, dataFiltered.length - 1));
            } else {
              series.data.setAll(dadosGrafico.filter(a => a.icr_id_agente_holograma == idAgenteHolograma));
            }
        }

    }, [dados])


    return (
      <div id={id} style={{ width: "100%", minHeight: "100%", opacity: 0 }}></div>
    )
}

export default memo(Grafico)
