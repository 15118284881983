import React, { useContext, useState }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from './Filters'
import Graph from './Graph'
import GraphSupplication from './GraphSupplication'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`

function OportunityCloudPage() {
  const {
    ICREvolucao, getICREvolucao,
  } = useContext(RelativeIndicatorContext);
  const [tipoPesquisa, setTipoPesquisa] = useState('R');
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Histórico relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICREvolucao({
            ...payload,
          });
          setTipoPesquisa(payload.tipoPesquisa);
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={ICREvolucao.loading} size="big" />
      <Content>
        {(ICREvolucao.loading) ? (
          <p></p>
          ) : ICREvolucao && ICREvolucao.error ? (
            <p style={{margin: 20}}>Sem resultados para a busca</p>
          ) : (
          <>
            {tipoPesquisa === 'R' ? (
              <Graph
                graphData={ICREvolucao}
              />
            ) : (
              <GraphSupplication
                graphData={ICREvolucao}
              />
            )}
          </>
        )}
      </Content>
    </InternalLayout>
  );
}

export default OportunityCloudPage;
