import React from 'react';
import {
  HashRouter as Router,
  Switch, Redirect,
  Route,
} from "react-router-dom";

import './index.css';
import ThemeStyle from './styles/Theme';
import { AuthProvider } from './context/Auth';
import { RelativeIndicatorProvider } from './context/RelativeIndicator';
import { isAuthenticated } from './utils/token';
import Logout from './page/Logout';
import Login from './page/Login';
import ChangePassword from './page/ChangePassword';
import ForgotMyPassword from './page/ForgotMyPassword';
import PasswordRecovery from './page/PasswordRecovery';
import NotFound from './page/NotFound';
import Dashboard2 from './page/Dashboard';
import Dashboard from './page';
import Home from './page/Home';
import AboutUs from './page/AboutUs';
import Plans from './page/Plans';
import RelativeIndicators from './page/RelativeIndicators';
import BussinessLayers from './page/BussinessLayers';
import CultureIndicators from './page/CultureIndicator';
import RelationalCompetence from './page/RelationalCompetence';
import ProminentSupplication from './page/ProminentSupplication';
import SupplicationDashboard from './page/SupplicationDashboard';
import OportunityCloudPage from './page/OportunityCloud';
import RelationalPositionPage from './page/RelationalPosition';
import RelationalHistoricPage from './page/RelationalHistoric';

function App() {
  const PrivateRoute = ({component: Component, ...rest}) => {
    return (
      <Route
        {...rest}
        render={(props) => isAuthenticated() === true
          ? <Component {...props} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
      />
    )
  }

  return (
    <>
      <ThemeStyle />
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/redirect" component={props => {
              const queryParams = new URLSearchParams(props.location.search);
              window.location.href = (queryParams.get('to'));
              return null;
            }}/>
            <Route path="/logout" component={Logout} />
            <Route path="/login" component={Login} />
            <Route path="/entrar" component={Login} />
            <Route path="/trocar-senha" component={ChangePassword} />
            <Route path="/esqueci-minha-senha" component={ForgotMyPassword} />
            <Route path="/recuperacao-de-senha" component={PasswordRecovery} />
            <Route exact path="/" component={Home} />
            <Route path="/sobre-nos" component={AboutUs} />
            <Route path="/planos" component={Plans} />
            <RelativeIndicatorProvider>
              <PrivateRoute path="/indicadores-relacionais" component={RelativeIndicators} />
              <PrivateRoute path="/indicador-de-cultura" component={CultureIndicators} />
              <PrivateRoute path="/camadas-de-negocio" component={BussinessLayers} />
              <PrivateRoute path="/competencia-relacional" component={RelationalCompetence} />
              <PrivateRoute path="/suplicas-em-destaque" component={ProminentSupplication} />
              <PrivateRoute path="/painel-das-suplicas" component={SupplicationDashboard} />
              <PrivateRoute path="/nuvem-de-oportunidades" component={OportunityCloudPage} />
              <PrivateRoute path="/posicionamento-relacional" component={RelationalPositionPage} />
              <PrivateRoute path="/historico-relacional" component={RelationalHistoricPage} />
              <PrivateRoute exact path="/dashboard" component={Dashboard2} />
              <PrivateRoute exact path="/legacy-dashboard" component={Dashboard} />
              {/* <Route path="*" component={NotFound} /> */}
            </RelativeIndicatorProvider>
          </Switch>
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
