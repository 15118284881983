const isProduction = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production';

const indexRoute = isProduction ? 'prod' : 'dev';

export const poolData = isProduction ? {
  UserPoolId: 'us-west-2_fOV8ptAFk',
  ClientId: '6hdt77hai051ieb2tf9b48hck4'
} : {
  UserPoolId: 'us-west-2_67V0k6htA',
  ClientId: '619rq03e3mnb5588cshku0ubnk'
};

export const apiRoute = `https://${indexRoute}.api.capitalrelacional.app/api`;
