import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import styled from 'styled-components';
// import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { useDispatch } from "react-redux";
// import { requisitarDados } from "../../store/menus/actions";
// import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import Loading from 'components/Loading';
// import { useSelector } from "react-redux";
// import cliente from "../../cliente";
// import gerarVariaveisAmbiente from "../../helpers/gerarVariaveisAmbiente";
import Cloud from 'assets/capital/cloud.svg';
import UsersClass from 'assets/capital/users-class.svg';
import Pray from 'assets/capital/pray.svg';
import StreetView from 'assets/capital/street-view.svg';
import Fireplace from 'assets/capital/fireplace.svg';
import PollPeople from 'assets/capital/poll-people.svg';
import Diagnoses from 'assets/capital/diagnoses.svg';
import UserVisor from 'assets/capital/user-visor.svg';
import UserChart from 'assets/capital/user-chart.svg';
import BG from 'assets/capital/fundo-banner.png';

import MOCK from './menu-mock'

const icons = {
  cloud: Cloud,
  usersClass: UsersClass,
  pray: Pray,
  streetView: StreetView,
  fireplace: Fireplace,
  pollPeople: PollPeople,
  diagnoses: Diagnoses,
  userVisor: UserVisor,
  userChart: UserChart,
};

const Body = styled.div`
  background: url(${BG}) no-repeat center;
  background-color: #FFF;
  background-size: 100% 100%;
  padding: 94px 12px 55px;
  height: 100vh;
`;

const CardLight = styled.div`
  background: #54C5CE;
  border-radius: 10px;
  height: 150px;
  margin-bottom: 18px;
  text-align: center;

  :hover {
    cursor: pointer;
    background: #6bdae3;
  }

  img {
    margin-top: 25px;
    margin-bottom: 10px;
    height: 45px;
  }

  p {
    padding: 0 15px;
    font-size: 20px;
    color: #0F355F;
    font-weight: 400;
  }
`;

function Home() {
//   const dispatch = useDispatch();
//   const { carregando, atalhos, menuPrincipal } = useSelector(
//     (state) => state.Menus
//   );
//   const token = useSelector((state) => state.Login.user?.tokenNOL);
  const [variaveisAmbiente, setVariaveisAmbiente] = useState(MOCK.ResultadoObjeto);
  const [carregando, setCarregando] = useState(true);

//   useEffect(() => {
//     if (!token) return;

//     gerarVariaveisAmbiente(token)
//       .then(result => {
//         setVariaveisAmbiente(result);
//       })
//       .catch(error => console.log(error));

//   }, [token]);


  const verificarHabilitarBotao = (url) => {

    if (!url) return "btnPaginaPrincipalDisable";

    if (url.includes('https://')) return "btnPaginaPrincipal";

    switch (variaveisAmbiente?.USUARIO?.USUARIO_ICR_ID_LICENCA.toString()) {
      case "10":
        return "btnPaginaPrincipal"
      case "50":
        return "btnPaginaPrincipal"
      case "99":
        return "btnPaginaPrincipal"
      case "20":
        if (["indicadores-relacionais", "suplicas-em-destaque", "camadas-de-negocio", "painel-das-suplicas", "historico-relacional"].includes(url.replace("/", "")))
          return "btnPaginaPrincipal";
        else
          return "btnPaginaPrincipalDisable";
      case "30":
        if (["indicadores-relacionais", "painel-das-suplicas"].includes(url.replace("/", "")))
          return "btnPaginaPrincipal";
        else
          return "btnPaginaPrincipalDisable";
      case "90":
        if (["indicadores-relacionais", "camadas-de-negocio", "competencia-relacional", "painel-das-suplicas", "historico-relacional"].includes(url.replace("/", "")))
          return "btnPaginaPrincipal";
        else
          return "btnPaginaPrincipalDisable";
      default:
        return "btnPaginaPrincipalDisable"
    }
  }

  const atualizarDados = async (mes, ano) => {

    // if (cliente.id != 'capitalRelacional')
    //   await dispatch(requisitarDadosAmbiente(token));

    // await dispatch(requisitarDados(token, mes, ano));
  };

  useEffect(() => {
    atualizarDados();
    setTimeout(() => {
      setCarregando(false)
    }, 2000)
  }, []);

  const inserirIcone = (icone, titulo, urlAmigavel) => {
    localStorage.setItem("iconeBizAtual", icone);
    localStorage.setItem("tituloBizAtual", titulo);
    // props.history.push(urlAmigavel);
  };

  const mostrarLoader = carregando;
  return (
    <React.Fragment>
      <Loading show={mostrarLoader} size="big" />
      <Body >
        <Container fluid>
          {variaveisAmbiente &&
            <Row className="mb-2">
              <Col xs="6" className={"pr-5"}>
                {/* <Breadcrumbs title="Recursos Estruturais" breadcrumbItem="" /> */}
                <Row>
                  {variaveisAmbiente[0].subMenu?.map((sub, index) => {
                    if (index <= 5) {
                      return (
                      <Col xs="6" sm="4" lg="4" key={`${variaveisAmbiente.id}_${sub.id}`}>
                          <Card
                          className={verificarHabilitarBotao(sub.urlAmigavel.replace("/", ""))}
                          onClick={() => {
                              if (verificarHabilitarBotao(sub.urlAmigavel.replace("/", "")) == "btnPaginaPrincipalDisable") return;
                              if (sub.urlAmigavel.includes("https://")) {
                              const url = sub.urlAmigavel.replace("/", "");
                              window.open(url.substring(0, url.length - 1), "_blank")
                              }
                              else
                              inserirIcone(sub.icone, sub.nome, sub.urlAmigavel);
                          }}
                          >
                          <CardBody className="text-center text-secondary">
                              <i className={`${sub.icone} fa-4x mt-2`}></i>
                              <div className="boxtexto">
                              <p className="mb-0 mt-3">{sub.nome}</p>
                              </div>
                          </CardBody>
                          </Card>
                      </Col>
                      );
                    }
                  })}
                </Row>
              </Col>
              <Col xs="6" className={"pl-5"}>
                {/* <Breadcrumbs title="Informações Relacionais" breadcrumbItem="" /> */}
                <Row>
                  {variaveisAmbiente[0].subMenu?.map((sub, index) => {
                    if (index > 5)
                      return (
                        <Col xs="6" sm="4" lg="4" key={`${variaveisAmbiente.id}_${sub.id}`}>
                          <CardLight
                            onClick={() => {
                              window.location = ('/#' + sub.urlAmigavel)
                            }}
                          >
                            <img src={icons[sub.icone]} alt={sub.nome} />
                            <div className="boxtexto">
                              <p className="">{sub.nome}</p>
                            </div>
                          </CardLight>
                        </Col>
                      );
                  })}
                </Row>
              </Col>
            </Row>}
        </Container>
      </Body>
    </React.Fragment>
  );
};

export default Home;
