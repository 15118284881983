import React from "react";
import Timing from 'assets/capital/cil_clock.svg';

import {
  Button, Onboarding,
  OnboardingBox, TimingText,
} from './atoms';

function OnboardingComponent({ onClickButton }) {
  return (
    <Onboarding>
      <div>
        <h2>Onboarding</h2>
        <p>Onboarding simples e ágil para engajar a sua equipe na melhor gestão relacional. São três fases que estaremos lado a lado.</p>
        <OnboardingBox>
          <div>1</div>
          <div>
            <h3>Significação e Capacitação</h3>
            <p>Nessa primeira fase três ações nos guiarão: modelar, significar e engajar. Com um Gestor Relacional especialista da Capital Relacional vamos juntos modelar a melhor visibilidade de informações para o seu negócio, definir acessos, engajar, significar e capacitar as lideranças e responsáveis pela relação com os clientes.</p>
            <div>
              <img src={Timing} alt="" />
              <TimingText>3 encontros</TimingText>
            </div>
          </div>
        </OnboardingBox>
        <OnboardingBox>
          <div>2</div>
          <div>
            <h3>Acolhimento e acompanhamento</h3>
            <p>Nesta fase a sua equipe de líderes já estarão preparados para iniciar o processo, mas ainda estaremos de pertinho acompanhando cada passo. Os verbos orientadores serão: convidar, convocar e acompanhar. Este é o momento que vamos convidar os clientes, convocar a equipe e acompanhar o engajamento de cada respondente. </p>
            <div>
              <img src={Timing} alt="" />
              <TimingText>2 encontros</TimingText>
            </div>
          </div>
        </OnboardingBox>
        <OnboardingBox>
          <div>3</div>
          <div>
            <h3>Entendimento e compreensão</h3>
            <p>Pronto! Chegou a hora de aguçar a curiosidade e mergulhar nas informações. Faremos juntos a análise e leitura dos resultados para garantir as melhores interpretações e aprendizados. É o momento de aceitar o que foi revelado, compreender com profundidade e preparar boas conversas com a equipe e clientes.</p>
            <div>
              <img src={Timing} alt="" />
              <TimingText>3 encontros</TimingText>
            </div>
          </div>
        </OnboardingBox>
        <Button onClick={onClickButton} full>QUERO UMA DEMONSTRAÇÃO</Button>
      </div>
    </Onboarding>
  );
};

export default OnboardingComponent;
