import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Graph from './Graph'
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  padding-right: 10px;
  > div {
    height: calc(100vh - 100px);
  }
`

function CultureIndicatorsPage() {
  const {
    posicionamentoRelacional, getPosicionamentoRelacional,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  const [currentPayload, setCurrentPayload] = useState();
  const [agents, setAgents] = useState();

  return (
    <InternalLayout
      title="Posicionamento relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters
          onSearch={(payload) => {
            getPosicionamentoRelacional({
              ...payload,
            });
            setCurrentPayload(payload);
            setUsedSearch(!usedSearch);
          }}
          changeType={true}
          onAgents={(payload) => {
            setAgents(payload);
          }}
        />
      }
    >
      <Loading show={posicionamentoRelacional.loading} size="big" />
      <Content>
      {(posicionamentoRelacional.loading) ? (
        <p></p>
      ) : posicionamentoRelacional && !posicionamentoRelacional.length ? (
        <p style={{margin: 20}}>Sem resultados para a busca</p>
      ) : (
        <Graph
          graphData={posicionamentoRelacional}
          currentPayload={currentPayload}
          currentAgents={agents}
        />
      )}
      </Content>
    </InternalLayout>
  );
}

export default CultureIndicatorsPage;
