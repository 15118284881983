import 'cross-fetch/polyfill';
import { getData, setData, deleteData } from 'utils/token';
import { poolData } from 'env';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
let cognitoUser = userPool.getCurrentUser();

export const getRefreshToken = () => {
  if (!cognitoUser) {
    cognitoUser = userPool.getCurrentUser();
  }

  const data = getData();

  if (!data?.refreshToken?.token) {
    return;
  }

  if (!cognitoUser) {
    return;
  }

  cognitoUser.refreshSessionIfPossible();
}

export const clearUserData = () => {
  cognitoUser.clearCachedUserData();
  deleteData();
  cognitoUser.signOut(() => console.log('SignOut'));
};

export const getUserData = () => {
  if (!cognitoUser) {
    cognitoUser = userPool.getCurrentUser();
  }

  const data = JSON.parse(cognitoUser.getUserDataFromCache());

  let newUserData = {
    hasRelativeIndicators: false,
    hasCultureIndicators: false,
    hasBussinessLayers: false,
    hasRelationalCompetence: false,
    hasProminentSupplication: false,
    hasSupplicationDashboard: false,
    hasOportunityCloudPage: false,
    hasRelationalPositionPage: false,
    hasRelationalHistoricPage: false
  };

  /*
  Jarina tem indicador relacional, súplica em destaque, camada de negócio, painel das súplicas;
  Muacy tem indicador relacional, painel das súplicas.
  Edu tem indicador relacional, camadas de negócio, painel das súplicas e histórico relacional
  */

  data?.UserAttributes?.filter(item => item.Name.indexOf('custom:') > -1)
                       .forEach((item) => newUserData[(item.Name).replace('custom:', '')] = item.Value);

  newUserData.plan = ((newUserData.nameLicense || 'Admin').replace('Plano ', '')).toString();

  if ((newUserData.plan).toLowerCase() === "jarina") {
    newUserData.planClass = 'jarina';
    newUserData.hasRelativeIndicators = true;
    newUserData.hasProminentSupplication = true;
    newUserData.hasBussinessLayers = true;
    newUserData.hasSupplicationDashboard = true;
  } else  if ((newUserData.plan).toLowerCase() === "muacy") {
    newUserData.planClass = 'muacy';
    newUserData.hasRelativeIndicators = true;
    newUserData.hasSupplicationDashboard = true;
  } else if ((newUserData.plan).toLowerCase() === "edu") {
    newUserData.planClass = 'other';
    newUserData.hasRelativeIndicators = true;
    newUserData.hasSupplicationDashboard = true;
    newUserData.hasBussinessLayers = true;
    newUserData.RelationalHistoricPage = true;
  } else {
    if ((newUserData.plan).toLowerCase() === "kiran") {
      newUserData.planClass = 'kiran';
    } else {
      newUserData.planClass = 'other';
    }

    newUserData.hasRelativeIndicators = true;
    newUserData.hasCultureIndicators = true;
    newUserData.hasBussinessLayers = true;
    newUserData.hasRelationalCompetence = true;
    newUserData.hasProminentSupplication = true;
    newUserData.hasSupplicationDashboard = true;
    newUserData.hasOportunityCloudPage = true;
    newUserData.hasRelationalPositionPage = true;
    newUserData.hasRelationalHistoricPage = true;
  }

  return newUserData;
};
