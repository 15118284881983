import styled from 'styled-components';
import Background from 'assets/capital/fundo-banner.png';
import BackgroundCR from 'assets/capital/banner-cr.svg';

export const Body = styled.div`
  font-family: 'Leelawadee';

  @media(min-width: 1366px) {
    background: url(${Background}) no-repeat;
    background-color: #FFF;
    background-size: 100%;
  }
`;

export const BgHeader = styled.div`
  @media(max-width: 1365px) {
    background: hsla(212, 73%, 22%, 1);
    background: linear-gradient(270deg, hsla(212, 73%, 22%, 1) 0%, hsla(207, 73%, 32%, 1) 56%, hsla(210, 75%, 40%, 1) 100%);
    background: -moz-linear-gradient(270deg, hsla(212, 73%, 22%, 1) 0%, hsla(207, 73%, 32%, 1) 56%, hsla(210, 75%, 40%, 1) 100%);
    background: -webkit-linear-gradient(270deg, hsla(212, 73%, 22%, 1) 0%, hsla(207, 73%, 32%, 1) 56%, hsla(210, 75%, 40%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0F355F", endColorstr="#16568B", GradientType=1 );
  }
`;

export const Header = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 70px auto 120px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  img {
    width: 300px;
  }

  nav {
    a {
      color: #FFF;
      padding: 0 20px;
    }
  }
`;

export const BannerWrapper = styled.div`
  color: #FFF;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 20px;
  display: flex;

  @media(max-width: 1365px) {
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;

    .illustration {
      max-width: 35%;
    }
  }
`;

export const BannerText = styled.p`
  padding-top: 10px;
  font-size: 86px;
  line-height: 94px;
  letter-spacing: -5px;

  @media(max-width: 1365px) {
    font-size: 64px;
    line-height: 72px;
  }
`;

export const BannerBottomText = styled.p`
  padding-top: 20px;
  font-size: 32px;

  @media(max-width: 1365px) {
    font-size: 24px;
    max-width: 500px;
  }
`;

export const Button = styled.button`
  font-weight: bold;
  background: #47D2A4;
  border-radius: 5px;
  border: 0;
  color: #000;
  padding: 20px 40px;
  width: ${props => (props.full) ? '100%' : 'auto'};
  font-size: 16px;

  :hover {
    opacity: 0.8;
  }

  @media(max-width: 1365px) {
    padding: 16px 30px;
  }
`

export const NaMidia = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 120px auto;
  color: #0F355F;
  display: none;

  p {
    color: #E87613;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
      background: #C4C4C4;
      border-radius: 5px;
      height: 100px;
      width: 160px;
    }
  }
`;

export const Suplicas = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 20px;
  text-align: center;

  h2 {
    font-size: 72px;
    line-height: 70px;
    letter-spacing: -5px;
    font-weight: bold;
    color: #0F355F;
    max-width: 1060px;
    margin: 0 auto 24px;

    em {
      color: #EA4E59;
      font-style: initial;
    }

    @media(max-width: 1365px) {
      font-size: 58px;
      line-height: 54px;
    }
  }

  p {
    font-size: 32px;
    line-height: 38px
    color: #0F355F;

    @media(max-width: 1365px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media(max-width: 1365px) {
    padding: 40px 20px;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: fit-content(50%) fit-content(50%);
  grid-gap: 20px;
  max-width: 1280px;
  padding: 70px 20px;
  width: 100%;
  margin: 0 auto;

  @media(max-width: 900px) {
    grid-template-columns: fit-content(100%);
  }
`;

export const CardsList = styled.div`
  width: 100%;
  max-width: 100%;

  > div {
    margin-bottom: 20px;
    min-height: 600px;
    width: 100%;
    max-width: 100%;
  }

  .card {
    text-align: end;
  }

  .long-text {
    text-align: right;
  }

  > {
    :nth-child(odd) {
      background: #F07F35;
    }

    :nth-child(even) {
      background: #FACD1B;
    }
  }
`;

export const CardsListReverse = styled.div`
  > div {
    margin-bottom: 20px;
    min-height: 600px;
  }

  .long-text {
    text-align: left;
  }

  > {
    :nth-child(even) {
      background: #F07F35;
    }

    :nth-child(odd) {
      background: #FACD1B;
    }
  }
`;

export const Card = styled.div`
  padding: 40px;
  color: #0F355F;
  font-size: 84px;
  cursor: pointer;

  p {
    font-weight: bold;
    line-height: 100px;
    margin: 0;
  }

  > div {
    display: inline-block;
    margin: 0 auto;
  }

  :hover {
    .long-text {
      height: auto;
    }
  }
`;

export const LongText = styled.div`
  height: 0;
  font-size: 38px;
  overflow: hidden;
  padding: 20px 90px 0;
`;

export const Market = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 70px auto;
  text-align: center;

  p {
    color: #0F355F;
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 40px;
  }
`;

export const FormulaBanner = styled.div`
  padding: 70px 0;
  background: #1F7CBF;
  font-size: 100px;

  > div {
    max-width: 1280px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;

    @media(max-width: 900px) {
      flex-flow: column nowrap;
    }
  }
`;

export const FormulaBannerFirst = styled.div`
  font-size: 60px;
  text-align: center;

  p {
    margin: 0;
    line-height: 60px;
  }

  em {
    margin: 0;
    line-height:98px;
    font-style: initial;
    font-size: 108px;
    font-weight: bold;
  }
`;

export const FormulaBannerRounded = styled.div`
  background: #0F355F;
  text-align: center;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  border-radius: 130px;

  span {
    font-size: 28px;
    line-height: 30px;

    b {
      font-size: 46px;
    }
  }
`;

export const FormulaBannerRoundedGreen =  styled(FormulaBannerRounded)`
  background: #089CA3;
`

export const Plataforma = styled.div`
  color: #0F355F;
  text-align: center;
  max-width: 1280px;
  width: 100%;
  margin: 70px auto;
  padding: 0 20px;

  h2 {
    font-size: 68px;
    color: #0F355F;
  }

  p {
    font-size: 34px;
  }
`;

export const PlataformaBoxes = styled.div`
  display: grid;
  grid-template-columns: fit-content(33%) fit-content(33%)  fit-content(33%);
  grid-gap: 20px;
  margin: 60px 0;

  > div {
    background: #66757F40;
    padding: 20px;

    h3 {
      color: #0F355F;
      font-size: 30px;
      margin-bottom: 26px;
      padding: 0 8px;
    }

    p {
      font-size: 24px;
    }
  }

  @media(max-width: 900px) {
    grid-template-columns: fit-content(100%);
  }
`;

export const WomanBannerWrapper = styled.div`
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background: url(${BackgroundCR}) no-repeat;
  position: relative; /* This is important */
  overflow: hidden;

  @media(max-width: 900px) {
    img {
      display: none;
    }
  }
`;

export const WomanBannerText = styled.div`
  margin-top: 220px;
  margin-left: 200px;
  max-width: 550px;

  h3 {
    font-size: 90px;
    color: #FFF;
  }

  p {
    color: #FFF;
    font-size: 22px;
  }
`;

export const Strategy = styled.div`
  color: #0F355F;
  text-align: center;
  max-width: 1240px;
  width: 100%;
  margin: 70px auto;

  h2 {
    font-size: 68px;
    color: #0F355F;
  }

  p {
    font-size: 34px;
  }

  ul {
    position: relative;
    padding: 0;
    width: 100%;
    margin: 40px 0;
    padding-top: 20px;
    list-style-type: none;

    @media(min-width: 940px) {
      :before {
        position: absolute;
        left: 50%;
        top: 0;
        content: ' ';
        display: block;
        width: 2px;
        height: 100%;
        margin-left: -1px;
        background: #1F7CBF;
        z-index: 5;
      }
    }

    li {
      position: relative;
      display: flex;
      text-align: start;

      @media(min-width: 940px) {
        text-align: end;
        padding-right: 100px;

        :before {
          position: absolute;
          left: 49%;
          top: 6px;
          margin-left: -2px;
          content: ' ';
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background: #1F7CBF;
          z-index: 5;
        }
      }

      div {
        @media(max-width: 939px) {
          margin-bottom: 30px;
        }

        @media(min-width: 940px) {
          width: 50%;

          :before {
            position: relative;
            left: 102%;
            top: 20px;
            content: ' ';
            display: block;
            width: 40px;
            height: 1px;
            background: #1F7CBF;
            z-index: 5;
          }
        }

        h4 {
          font-size: 32px;
          color: #0F355F;
        }

        p {
          font-size: 24px;
          margin: 0;
        }
      }

      @media(min-width: 940px) {
        :nth-child(even) {
          justify-content: flex-end;
          text-align: initial;
          padding-right: 0;
          padding-left: 100px;

          div {
            :before {
              left: -9%;
            }
          }
        }
      }
    }
  }
`;

export const FullCards = styled.div`
  display: grid;
  grid-template-columns: fit-content(50%) fit-content(50%);
  grid-gap: 20px;

  @media(max-width: 899px) {
    grid-template-columns: fit-content(100%);
  }

  > div {
    color: #FFF;
    font-size: 32px;
    padding: 34px;

    @media(min-width: 900px) {
      :nth-child(odd) {
        padding-left: 68px;
        text-align: end;
      }

      :nth-child(even) {
        padding-right: 68px;
      }
    }

    :nth-child(1) {
      background: #089CA3;
    }

    :nth-child(2) {
      background: #F07F35;
    }

    :nth-child(3) {
      background: #FACD1B;
      color: #0F355F;
    }

    :nth-child(4) {
      background: #0F355F;
    }
  }
`;

export const Plans = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  max-width: 1240px;
  width: 100%;
  margin: 70px auto;
  box-sizing: border-box;

  @media(max-width: 900px) {
    flex-flow: column nowrap;
    padding: 0 10px;
  }
`;

export const Plan = styled.div`
  width: 300px;
  background: ${props => (props.blued) ? '#1F7CBF' : '#07B5BE'};
  padding: 24px;
  color: #FFF;
  font-size: 24px;
  position: relative;
  border-radius: 5px;

  h3 {
    color: #FFF;
    font-size: 56px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  p {
    padding-bottom: 30px;
  }

  @media(max-width: 900px) {
    margin-bottom: 40px;
    width: 100%;
  }
`;

export const BestPlan = styled(Plan)`
  background: #F07F35;

  > div {
    position: relative;
    display: inline-block;
    background: #FACD1B;
    font-size: 16px;
    color: #000000;
    padding: 15px;
    top: -44px;
    border-radius: 5px;
  }
`;

export const ButtonPlan = styled.button`
  font-weight: bold;
  font-size: 16px;
  background: ${props => (props.blued) ? '#88C9F9' : (props.yellowed) ? '#FFC400' : '#3CF1B0'};
  border-radius: 5px;
  border: 0;
  color: #000;
  padding: 10px;
  width: min-content;
  margin-bottom: 30px;

  :hover {
    opacity: 0.8;
  }
`
