import React from "react";
import Logo from 'assets/capital-relacional.png';

import { Header } from './atoms';

function HeaderComponent() {
  return (
    <Header>
      <img src={Logo} alt="Capital Relacional" />
      <nav>
        <a href="/#/login">Plataforma</a>
        <a href="/#/">Onboarding</a>
        <a href="/#/sobre-nos">Sobre nós...</a>
        <a href="/#/login">Entrar</a>
      </nav>
    </Header>
  );
};

export default HeaderComponent;
