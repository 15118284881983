import React from 'react';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import Knob from "../../components/Knob";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  background: #f8f8fb;
  z-index: 1;
  padding-top: 70px;

  .list-item {
    font-size: 12px;
    font-weight: 600;
  }
`

const BackButton = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;

  i {
    padding-right: 10px;
  }

  :hover {
    opacity: .8
  }
`

const BoaConversa = ({ respondente, suplicas, onClose }) => {
  const cores = {
    "success": "#34c38f",
    "warning": "#f1b44c",
    "danger": "#f46a6a"
  }

  return (
    <React.Fragment>
      <>
        <Wrapper>
          <Container className={"mt-4"}>
            <Row>
              <Col sm={12}>
                <BackButton onClick={() => onClose()}>
                  <i className="fa fa-arrow-left"></i>
                  Voltar
                </BackButton>
                <Card>
                  <CardBody style={{ color: "black" }}>
                    <h1 className={"mb-0 font-weight-bolder"} style={{ marginTop: "-11px" }}>
                      {respondente.icr_nome_respondente}
                    </h1>
                    <h5 className={"text-uppercase"} style={{ fontWeight: 300 }}>
                      {respondente.icr_empresa_respondente}
                    </h5>
                    <div className={"w-100"} style={{ fontSize: "11px" }} >
                      {(respondente.icr_nome_agente_holograma + "|" + respondente.icr_camadas).split("|").map((camada, camada_id) => {
                        return (
                          <span className={"py-1 px-2 mr-2"} style={{ backgroundColor: "#d9d9d9" }} key={`camada_${camada_id}`}>
                            {camada}
                          </span>
                        )
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {
                [1, 2, 3, 4, 5, 6, 7, 8].map(idSuplica => {
                  let coresFrases = respondente[`icr_cores_respondente_${idSuplica}`].split('|;');
                  
                  return (
                    <Col sm={6} className={"mb-4"} key={`suplica_${idSuplica}`}>
                      <Card style={{ height: "100%" }}>
                        <CardBody className={"pt-3 px-3 pb-0"}>
                          <div className={"font-weight-bold"}>{suplicas[`nome_suplica_${idSuplica}`]}</div>
                          <div className={"w-100 d-flex"}>
                            <div className={"ml-3 mt-3 align-self-center"} style={{ maxHeight: "120px" }}>
                              <Knob
                                value={respondente[`suplica_retorno_${idSuplica}`]}
                                width={115}
                                // fgColor="#7a6fbe"
                                fgColor={cores[respondente[`badge_${idSuplica}`]]}
                                displayCustom={() => {
                                  return false
                                }}
                                onChange={() => { }}
                              />
                            </div>
                            <div className={"mt-3 align-self-center"} style={{ color: "#545454", fontSize: "11px" }}>
                              {respondente[`icr_frases_respondente_${idSuplica}`] &&
                                <ul className={"mb-0"}>
                                  {
                                    respondente[`icr_frases_respondente_${idSuplica}`].split("|;").map((frase, idFrase) => {
                                      if (frase) {
                                        return (
                                          <li className={`list-item text-${coresFrases[idFrase]}`} key={`suplica_${idSuplica}_${idFrase}`}>
                                            {frase}
                                          </li>
                                        )
                                      }
                                      return ("")
                                    })
                                  }
                                </ul>}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          </Container>
        </Wrapper>
      </>
    </React.Fragment>
  );
}
export default BoaConversa;