import styled from 'styled-components';
import BGHuman from 'assets/capital/BGHuman.svg'

export const Button = styled.button`
  font-weight: bold;
  background: #47D2A4;
  border-radius: 5px;
  border: 0;
  color: #000;
  padding: 20px 40px;
  width: ${props => (props.full) ? '100%' : 'auto'};

  :hover {
    opacity: 0.8;
  }
`

export const Header = styled.div`
  max-width: 1280px;
  padding: 70px 20px 120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  img {
    width: 300px;
  }

  nav {
    a {
      color: #FFF;
      padding: 20px;
      font-size: 20px;
    }
  }

  @media(max-width: 900px) {
    img {
      max-width: 150px;
    }

    nav {
      a {
        padding: 14px;
        font-size: 18px;
      }
    }
  }

  @media(max-width: 1365px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Onboarding = styled.div`
  background-image: linear-gradient(to bottom right, #114270, #1E7ABD);
  color: #FFF;

  > div {
    text-align: center;
    max-width: 1240px;
    margin: 0 auto;
    padding: 40px 0;
  }

  h2 {
    font-size: 68px;
    color: #FFF;
  }

  p {
    font-size: 34px;
  }
`;

export const OnboardingBox = styled.div`
  display: flex;
  text-align: initial;

  p {
    font-size: 24px;
  }

  h3 {
    color: #FFF;
    font-size: 48px;
    font-weight: bold;
  }

  > div {
    :nth-child(1) {
      font-size: 240px;
      font-weight: bold;
      min-width: 200px;
      text-align: center;
    }

    :nth-child(2) {
      padding-top: 50px
    }
  }
`

export const TimingText = styled.span`
  text-decoration: underline;
  font-size: 24px;
  font-weight: bold;
  margin-left: 12px;
  position: relative;
  top: 3px;
`;

export const Humans = styled.div`
  position: relative;
  background: url(${BGHuman}) no-repeat;
  background-size: 100%;
  height: 570px;

  img {
    position: absolute;
    height: 570px;

    :nth-child(1) {
      left: 0;
    }
    :nth-child(2) {
      right: 0;
    }
  }

  > div {
    text-align: center;
    width: 730px;
    margin: 0 auto;
    padding: 270px 0 20px;

    p {
      font-size: 56px;
      line-height: 60px;
      color: #FFF;

      :nth-child(2) {
        font-size: 36px;
      }
    }
  }
`;
