import React from "react";
import Book from 'assets/capital/capital-relacional-book.png';
import Tranjan from 'assets/capital/roberto-tranjan.png';
import Tairone from 'assets/capital/tairone-oliveira.png';
import Conventi from 'assets/capital/francesco-conventi.png';

import Footer from 'components/Landing/Footer';
import Contact from 'components/Landing/Contact';
import Header from 'components/Landing/Header';
import Humans from 'components/Landing/Humans';

import {
  Body, StrategyBanner,
  Title, Founders, Strategy,
} from './atoms';

function AboutUs() {
  return (
    <Body >
      <Header />
      <Strategy>
        <h2>Sobre nós...</h2>
        <p>Somos uma empresa de tecnologia com competências em educação que apresenta um conceito inovador para medir a qualidade das relações humanas nas organizações.</p>
        <p>Nossa plataforma possui recursos inéditos para a gestão do Capital Relacional, entregando informações estratégicas e aprendizados para a liderança elevar a qualidade das relações e alcançar os melhores resultados.</p>
      </Strategy>
      <StrategyBanner>
        <div>
          <p>A partir de uma obra literária singular sobre o conceito de súplicas nas relações de trabalho e negócios, criamos as súplicas e o ICR (Índice de Capital Relacional), métricas únicas para avaliar a qualidade das relações com clientes, líderes e colaboradores.</p>
          <img src={Book} alt="capital-relacional-book" />
        </div>
      </StrategyBanner>
      <Strategy>
        <p>Essa métrica nos ajuda a entender que a humanização dos negócios é o caminho para a conquista dos melhores resultados, afinal equipe comprometida + cliente fidelizado = lucro.</p>
        <p>Nosso propósito maior é a hunanização dos negócios através do desenvolvimento de uma cultura empreendedora de relacionamento e resultados.</p>
      </Strategy>
      <Humans />
      <Title>
        Os idealizadores
      </Title>
      <Founders>
        <div>
          <img src={Tranjan} alt="" />
          <div className="info">
            <h3>Roberto Tranjan</h3>
            <h4>Sócio e fundador</h4>
            <p>Roberto é empresário, escritor, palestrante e educador empresarial que através das suas empresas, livros e programas de educação constrói o firme propósito de desenvolver uma cultura ética, humana e próspera no trabalho e nos negócios.</p>
            <p>Iniciou sua trajetória empreendedora há mais de 30 anos, criando métodos, estratégias e filosofia que dentro de processos de educação andragógica apoiam empresários, líderes e profissionais a expandir seu campo de visão e ampliar competências para realizar negócios e trabalhos com propósito e resultados.</p>
            <p>Suas experiências atuando nesse campo inspiram empresários a se destacarem nos mercados ao mesmo tempo que constroem pedaços de planeta que dão certo.</p>
          </div>
        </div>
      </Founders>
      <Founders bg="#1F7CBF">
        <div>
          <div className="info right">
            <h3>Tairone Oliveira</h3>
            <h4>Sócio e CEO</h4>
            <p>Tairone é Mussum Ipsum, cacilds vidis litro abertis. In elementis mé pra quem é amistosis quis leo.Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Não sou faixa preta cumpadi, sou preto inteiris, inteiris.A ordem dos tratores não altera o pão duris.</p>
          </div>
          <img src={Tairone} alt="" />
        </div>
      </Founders>
      <Founders bg="#356C99">
        <div>
          <img src={Conventi} alt="" />
          <div className="info">
            <h3>Francesco Conventi</h3>
            <h4>Sócio e CTO</h4>
            <p>Francesco é Mussum Ipsum, cacilds vidis litro abertis. In elementis mé pra quem é amistosis quis leo.Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Não sou faixa preta cumpadi, sou preto inteiris, inteiris.A ordem dos tratores não altera o pão duris.</p>
          </div>
        </div>
      </Founders>
      <Contact />
      <Footer />
    </Body>
  );
};

export default AboutUs;
