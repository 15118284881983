import React, { useRef, useLayoutEffect } from 'react';
// import { API } from '../../../config/index';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais  } from "../../utils";

const Graph = ({ variant, children, graphData, ...props }) => {
  const chartReferencia = useRef(null);
  const dadosGrafico = graphData || [];

  useLayoutEffect(() => {
    if (dadosGrafico.length > 0) {
      document.querySelector(`#chartdiv`).innerHTML = "";
      var root = am5.Root.new("chartdiv");

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      root.locale = am5locales_pt_BR;

      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        paddingTop: 40,
      }));

      let legend = chart.children.unshift(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        y: 15,
      }));

      var data = [];
      dadosGrafico.map(dado => {
        for (let i = 1; i <= 8; i++) {
          data.push({
            suplica: `[fontFamily: Leelawadee]${dado[`suplica_${i}_nome`]}[/]`,
            nao_atendidas: dado[`suplica_${i}_naoatendidas`],
            tamanho_nao_atendidas: dado[`suplica_${i}_naoatendidas`] == 0 ? "0px" : "14px",
            desatendidas: dado[`suplica_${i}_desatendidas`],
            tamanho_desatendidas: dado[`suplica_${i}_desatendidas`] == 0 ? "0px" : "14px",
            atendidas: dado[`suplica_${i}_atendidas`],
            tamanho_atendidas: dado[`suplica_${i}_atendidas`] == 0 ? "0px" : "14px",
          });
        }
      });

      var xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,

      });
      xRenderer.grid.template.set("strokeOpacity", 0);
      xRenderer.labels.template.setAll({ fontSize: 12 });

      var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "suplica",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);

      var yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.labels.template.setAll({ visible: false });

      yRenderer.grid.template.set("strokeOpacity", 0);

      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer
      }));



      function makeSeries(name, fieldName, stacked, fill, fillStyle) {
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          stacked: stacked,
          name: `[fontFamily: Leelawadee]${name}[/]`,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "suplica",
          fill: am5.color(fill)
        }));

        series.data.setAll(data);

        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              text: `[fontFamily: Leelawadee fontSize:{tamanho_${fieldName}}]{valueY}[/]`,
              fill: am5.color("#fff"),
              centerY: am5.percent(50),
              centerX: am5.percent(50),
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }

      makeSeries("Não atendidas", "nao_atendidas", false, "#f1b44c", "#fff");
      makeSeries("Desatendidas", "desatendidas", true, "#f46a6a", "#fff");
      makeSeries("Atendidas", "atendidas", false, "#34c38f", "#fff");

      chart.appear(1000, 100);
    }
  }, [graphData])

  let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 30;
  let alturaFinal = ""
  if (window.outerHeight < 600) {
      alturaGrafico = alturaGrafico * 4;
      alturaFinal = `calc(100vh)`;
  } else {
      alturaFinal = `calc(100vh - 113px)`;
  }

  return (
    <div>
      <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    </div>
  );
};

export default React.memo(Graph);
