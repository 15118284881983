import styled from 'styled-components';

export const Body = styled.div`
  background-image: linear-gradient(to bottom right, #114270, #1E7ABD);
  background-color: #FFF;
  background-repeat:no-repeat;
  background-size:100% 210px;
  position: absolute;
`;

export const Strategy = styled.div`
  color: #0F355F;
  max-width: 1240px;
  margin: 50px auto;

  h2 {
    font-size: 68px;
    margin-bottom: 48px;
    color: #0F355F;
  }

  p {
    font-size: 30px;
  }
`;

export const StrategyBanner = styled.div`
  color: #FFF;
  background: #089CA3;
  position: relative;
  width: 100vw;

  > div {
    max-width: 1240px;
    margin: 10px auto;

    p {
      max-width: calc(100% - 300px);
      padding: 35px 0;
      font-size: 30px;
      font-weight: bold;
    }

    img {
      position: absolute;
      right: 0;
      top: -30px;
    }
  }
`;

export const Title = styled.div`
  background: #F07F35;
  padding: 50px 0;
  color: #0F355F;
  font-size: 68px;
  font-weight: bold;
  text-align: center;
`;

export const Founders = styled.div`
  background: ${props => props.bg ? props.bg : ' #0F355F'};
  font-size: 24px;

  > div {
    max-width: 1240px;
    margin: 0 auto;
    padding: 45px 0;
    display: flex;

    .info {
      padding: 0 20px;

      &.right {
        text-align: end;
      }
    }

    h3 {
      color: #FFF;
      font-size: 56px;
      margin: 0;
    }

    h4 {
      color: #F07F35;
      font-size: 32px;
      margin: 0;
    }

    p {
      color: #FFF;
      margin-top: 24px;
    }
  }
`;
