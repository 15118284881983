
import * as am4core from "@amcharts/amcharts4/core";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";
import * as am4charts from "@amcharts/amcharts4/charts";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";

export function retornarGraficoCapitalRelacionalDinamico(value, id, record, largura) {

    var cellElement = document.getElementById(id);
    if (!cellElement) {
        setTimeout(() => {
            retornarGraficoCapitalRelacionalDinamico(value, id, record, largura);
        })
        return;
    }

    if (cellElement?.children?.length > 0) return;

    const containerGrafico = document.createElement("div");
    containerGrafico.style.position = "absolute";
    containerGrafico.style.top = 0;
    containerGrafico.style.left = "calc( 50% - 50px )"

    const grafico = document.createElement("canvas");
    grafico.id = `${record.id}_suplica1`;
    grafico.width = "100";
    grafico.height = "70";

    cellElement.appendChild(containerGrafico);
    containerGrafico.appendChild(grafico);

    var ctx = grafico.getContext("2d");

    var gradient = ctx.createLinearGradient(0, 0, 100, 0);
    // gradient.addColorStop("0.05", "#fb7116");
    // gradient.addColorStop("0.5", "#f6d32b");
    gradient.addColorStop("0", "#f46a6a");
    gradient.addColorStop("0.5", "#f46a6a");
    gradient.addColorStop("0.51", "#f1b44c");
    gradient.addColorStop("0.81", "#f1b44c");
    gradient.addColorStop("0.82", "#34c38f");
    gradient.addColorStop("1.0", "#34c38f");

    // gradient.addColorStop("0.82", "#666");
    // gradient.addColorStop("1.0", "#999");

    ctx.beginPath();
    ctx.arc(50, 50, 40, 1 * Math.PI, 0);
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 5;
    ctx.stroke();

    const v2 = 180;
    const valorInicial = value + 100;
    const v3 = v2 * valorInicial
    const graus = v3 / 200;


    const containerPonteiro = document.createElement("div");
    containerPonteiro.style.position = "absolute";
    containerPonteiro.style.width = "35px";
    containerPonteiro.style.height = "2px";
    containerPonteiro.style.top = "47px";
    containerPonteiro.style.left = "calc( 50% - 35px )";
    containerPonteiro.style.backgroundColor = "#000";
    containerPonteiro.style.transformOrigin = "100% 50%";
    containerPonteiro.style.transform = `rotate(${graus}deg)`;

    cellElement.appendChild(containerPonteiro);

    const containerBasePonteiro = document.createElement("div");
    containerBasePonteiro.style.position = "absolute";
    containerBasePonteiro.style.width = "4px";
    containerBasePonteiro.style.height = "4px";
    containerBasePonteiro.style.left = "calc( 50% - 2px )";
    containerBasePonteiro.style.top = "46px";
    containerBasePonteiro.style.borderRadius = "50%";
    containerBasePonteiro.style.backgroundColor = "#000";

    cellElement.appendChild(containerBasePonteiro);

}

export function retornarGraficoCapitalRelacional(value, cellElement, record) {

    // debugger;
    //cellElement?.innerHTML = "";
    cellElement.innerHTML = ""
    
    // if (cellElement?.children?.length > 0) return;

    const containerGrafico = document.createElement("div");
    containerGrafico.style.position = "absolute";
    containerGrafico.style.top = "6px";

    const grafico = document.createElement("canvas");
    grafico.id = `${record.id}_suplica1`;
    grafico.width = "100";
    grafico.height = "70";

    cellElement.appendChild(containerGrafico);
    containerGrafico.appendChild(grafico);

    var ctx = grafico.getContext("2d");

    var gradient = ctx.createLinearGradient(0, 0, 100, 0);
    gradient.addColorStop("0", "#f46a6a");
    gradient.addColorStop("0.5", "#f46a6a");
    gradient.addColorStop("0.51", "#f1b44c");
    gradient.addColorStop("0.81", "#f1b44c");
    gradient.addColorStop("0.82", "#34c38f");
    gradient.addColorStop("1.0", "#34c38f");

    // gradient.addColorStop("0.82", "#CCC");
    // gradient.addColorStop("1.0", "#EEE");


    ctx.beginPath();
    ctx.arc(50, 50, 40, 1 * Math.PI, 0);
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 5;
    ctx.stroke();

    const v2 = 180;
    const valorInicial = value + 100;
    const v3 = v2 * valorInicial
    const graus = v3 / 200;



    const containerPonteiro = document.createElement("div");
    containerPonteiro.style.position = "absolute";
    containerPonteiro.style.width = "35px";
    containerPonteiro.style.height = "2px";
    containerPonteiro.style.top = "53px";
    containerPonteiro.style.left = "calc( 50% - 35px )";
    containerPonteiro.style.backgroundColor = "#000";
    containerPonteiro.style.transformOrigin = "100% 50%";
    containerPonteiro.style.transform = `rotate(${graus}deg)`;

    cellElement.appendChild(containerPonteiro);

    const containerBasePonteiro = document.createElement("div");
    containerBasePonteiro.style.position = "absolute";
    containerBasePonteiro.style.width = "4px";
    containerBasePonteiro.style.height = "4px";
    containerBasePonteiro.style.left = "calc( 50% - 2px )";
    containerBasePonteiro.style.top = "52px";
    containerBasePonteiro.style.borderRadius = "50%";
    containerBasePonteiro.style.backgroundColor = "#000";

    cellElement.appendChild(containerBasePonteiro);

}

export function definirLocalizacaoAmCharts5(grafico) {
    grafico.locale = am5locales_pt_BR;
    grafico.setThemes([
        am5themes_Responsive.new(grafico)
    ]);
}

export function definirLocalizacaoAmCharts(grafico) {
    grafico.language.locale = am4lang_pt_BR;
    grafico.dateFormatter.language = new am4core.Language();
    grafico.dateFormatter.language.locale = am4lang_pt_BR;
    grafico.responsive.useDefault = true;
    grafico.responsive.enabled = true;
}

export function retornarFormatacaoAmCharts() {
    return {
        formatoValores: {
            decimal0Casas: "#,###.",
            decimal1Casa: "#,###.0",
            decimal2Casas: "#,###.00",
        }
    }
}

export function formatarEixoYAmCharts(eixoY, maxValor, parametros = {}) {
    eixoY.renderer.grid.template.strokeOpacity = 0.1;
    eixoY.renderer.grid.template.stroke = am4core.color("gray");
    eixoY.renderer.opposite = parametros.oposto || false;
    eixoY.title.text = parametros.titulo || "";
    if (maxValor) {
        eixoY.max = maxValor * 1.05;
        eixoY.min = 0;
    }
    eixoY.renderer.minGridDistance = 60;
    return eixoY;
}

export function formatarEixoXAmCharts(eixoX, parametros) {
    eixoX.renderer.grid.template.strokeOpacity = 0.1;
    eixoX.renderer.grid.template.stroke = am4core.color("gray");
    if (parametros?.categoria) {
        eixoX.dataFields.category = parametros.categoria;
    }
    eixoX.renderer.minGridDistance = parametros?.minGridDistance || 60;

    return eixoX
}


export function formatarSeriesAmCharts(series, parametros) {
    series.name = parametros.nome;
    series.stroke = am4core.color(parametros.cor);
    if (parametros.valorY) {
        series.dataFields.valueY = parametros.valorY;
    }
    if (parametros.valorX) {
        series.dataFields.dateX = parametros.valorX;
    }
    if (parametros.categoriaX) {
        series.dataFields.categoryX = parametros.categoriaX;
    }
    if (parametros.eixoY) {
        series.yAxis = parametros.eixoY;
    }
    if (parametros.chart.scrollbarX) {
        parametros.chart.scrollbarX = new am4charts.XYChartScrollbar();
        parametros.chart.scrollbarX.series.push(series);
    }
    if (parametros.tipo == "linha") {
        const bullet = new am4charts.CircleBullet();
        bullet.circle.fill = am4core.color(parametros.cor);
        bullet.circle.fillOpacity = 0.75;
        bullet.circle.radius = 3;
        series.bullets.push(bullet);
    }
    if (parametros.tipo == "colunas") {
        series.fill = am4core.color(parametros.cor).lighten(.5);
        series.strokeWidth = 2;
        series.clustered = false;
        series.toBack();
    }
    return series;
}


export function formatarValoresAmCharts(valueLabel, formatoNumeroGrafico, cor, sinalDepois) {
    valueLabel.label.text = `{valueY.formatNumber('${formatoNumeroGrafico}')}${sinalDepois || ""}`;
    valueLabel.label.fontSize = 15;
    valueLabel.label.fill = am4core.color("#495057")
    valueLabel.label.background.fill = am4core.color("gray");
    valueLabel.label.background.fillOpacity = 0.1;
    valueLabel.label.paddingRight = 5;
    valueLabel.label.paddingLeft = 5;
    valueLabel.label.paddingTop = 3;
    valueLabel.label.dy = -18;
    return valueLabel;
}

export function formatarValoresTooltipTextAmCharts(valueLabel, formatoNumeroGrafico, cor, sinal = "") {
    valueLabel.tooltipText = `{name}\n[bold font-size: 20]{valueY.formatNumber('${formatoNumeroGrafico}')}${sinal}`;
    valueLabel.tooltip.getFillFromObject = false;
    valueLabel.tooltip.background.fill = am4core.color(cor);
}

export function formatarDatasZoomAmCharts(eixo, dataInicial, dataFinal) {

    eixo.zoomToDates(
        new Date(dataInicial),
        new Date(dataFinal),
    );
}

export function retornarQtdeCategoriasZoomAmCharts() {
    let meses = 0;
    switch (window.outerWidth) {
        case 1280:
            meses = 7;
            break;
        case 1360:
            meses = 7;
            break;
        case 1366:
            meses = 7;
            break;
        case 1600:
            meses = 10;
            break;
        case 1680:
            meses = 10;
            break;
        case 1920:
            meses = 14;
            break;
        default:
            meses = 14
    }
    return meses;
}
