import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
// import Filters from 'containers/Filters';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import Loading from 'components/Loading';
import Graph from './Graph'
// import Filters from './Filters'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`

function CultureIndicatorsPage() {
  const {
    icrCamadas, getICRCamadas,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Cultura Relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICRCamadas({
            ...payload,
            visualizacao: 'relacao',
            analiseRelacoes: 1
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Content>
        {icrCamadas.loading ? (
          <Loading show={icrCamadas.loading} size="big" />
        ) : icrCamadas && icrCamadas.data && !icrCamadas.data.length ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Graph
            graphData={icrCamadas}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default CultureIndicatorsPage;
